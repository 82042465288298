module.exports = {
  siteTitle: 'Designer Sweet Treats', // <title>
  manifestName: 'Casual',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  // pathPrefix: `/gatsby-starter-casual/`, // This path is subpath of your hosting https://domain/portfolio
  pathPrefix: `/designer-sweet-treats/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Designer Sweet Treats',
  subHeading: '',
};
