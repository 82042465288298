import React from 'react';

export default function Footer() {
  return (
    <footer className="footer text-faded text-center py-5">
      
<section>
  {/* <div class="telephone"><h1><i class="fa fa-phone"></i><a class="telephone" href="tel:+611800512384"><strong>  1800 512 384</strong></a></h1></div> */}
</section>

      <div className="container">
        <p className="m-0 small"><p>Powered by <a class="telephone" href='https://www.kykit.com.au'>KykIT</a> | <a class="telephone" href='https://aws.amazon.com/'>Amazon Web Services</a> | <a class="telephone" href='https://www.gatsbyjs.org/'>GatsbyJS</a></p></p>
      </div>
    </footer>
  );
}
