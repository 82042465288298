import React, { Component } from 'react';
import config from '../../config';
import { Link } from 'gatsby';
export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
    };
  }
  toggleMenu = value => {
    this.setState({ openMenu: value });
  };

  render() {
    const { openMenu } = this.state;
    const { activeLink } = this.props;
    return (
      <nav className="navbar navbar-expand-lg navbar-light py-lg-4" id="mainNav">
        <div className="container">
          <a
            className="navbar-brand text-uppercase text-expanded font-weight-bold d-lg-none"
            href="/#"
          >
            {config.siteTitle}
          </a>

          <button
            onClick={_ => this.toggleMenu(!openMenu)}
            className={`navbar-toggler  ${openMenu ? '' : 'collapsed'}`}
            type="button"
            aria-controls="navbarResponsive"
            aria-expanded={openMenu}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${openMenu ? 'show' : ''}`}
            id="navbarResponsive"
          >
            <ul className="navbar-nav text-center mx-auto">
              
              <li
                className={`nav-item px-lg-4 ${
                  activeLink === 'home' ? 'active' : ''
                }`}
              >
                <Link className="nav-link text-uppercase text-expanded hvr-underline-from-center" to="/">
                  Home
                </Link>
              </li>
              
              <li
                className={`nav-item px-lg-4 ${
                  activeLink === 'Birthday' ? 'active' : ''
                }`}
              >
                <Link
                  className="nav-link text-uppercase text-expanded hvr-underline-from-center"
                  to="/birthday/"
                >
                  Birthday
                </Link>
              </li>
              
              <li
                className={`nav-item px-lg-4 ${
                  activeLink === 'Cupcakes' ? 'active' : ''
                }`}
              >
                <Link
                  className="nav-link text-uppercase text-expanded hvr-underline-from-center"
                  to="/cupcakes/"
                >
                  Cupcakes
                </Link>
              </li>

              <li
                className={`nav-item px-lg-4 ${
                  activeLink === 'Any Occasion' ? 'active' : ''
                }`}
              >
                <Link
                  className="nav-link text-uppercase text-expanded hvr-underline-from-center"
                  to="/anyoccasion/"
                >
                  Any Occasion
                </Link>
              </li>

              <li
                className={`nav-item px-lg-4 ${
                  activeLink === 'Flavours' ? 'active' : ''
                }`}
              >
                <Link
                  className="nav-link text-uppercase text-expanded hvr-underline-from-center"
                  to="/flavours/"
                >
                  Flavour & Price Guide
                </Link>
              </li>

              <li
                className={`nav-item px-lg-4 ${
                  activeLink === 'Our Philosophy' ? 'active' : ''
                }`}
              >
                <Link
                  className="nav-link text-uppercase text-expanded hvr-underline-from-center"
                  to="/ourphilosophy/"
                >
                  Our Philosophy
                </Link>
              </li>

              <li
                className={`nav-item px-lg-4 ${
                  activeLink === 'Contact Us' ? 'active' : ''
                }`}
              >
                <Link
                  className="nav-link text-uppercase text-expanded hvr-underline-from-center"
                  to="/contact/"
                >
                  Contact Us
                </Link>
              </li>

            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
